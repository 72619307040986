<template>
  <div>
    <el-card>
      <div class="topDiv">
        <el-input v-model="params.name" placeholder="请输入"></el-input>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-search" @click="searchClick">搜索</el-button>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-plus" @click="addClick()">添加</el-button>
      </div>
    </el-card>
    <div>
      <el-table :data="records" border style="width: 98%" :height="innerHeight">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="name" label="模式名称" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="type" label="类型" min-width="150" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ getDictName(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" min-width="200">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="updateClick(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>

    <!--  新增模式/ 修改模式-->
    <el-dialog :title="rowData ? '修改模式' : '新增模式'" :visible.sync="moShiDia" v-if="moShiDia" width="50%" @close="moShiDia = false">
      <moShiDia :rowData="rowData" @quXiaoClick="moShiDia = false" @diaQueDingClick="diaQueDingClick" :dictList="dictList"></moShiDia>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '@/components/common/pagination/Index.vue'
import { commonMixin } from '@/utils/mixin/index.js'
import moShiDia from '@/components/moShiDia.vue'
export default {
  components: { pagination, moShiDia },
  mixins: [commonMixin],
  data() {
    return {
      moShiDia: false,
      huoDongName: '',
      params: { pageNum: 1, pageSize: 10, name: '' },
      records: [],
      total: 0,
      rowData: '',
      dictList: [],
    }
  },

  async mounted() {
    let dictRes = await this.$API.dict.getDictTypeList({ type: 4 })
    if (dictRes.status == 200) {
      dictRes.data.forEach((t) => (t.code = Number(t.code)))
      this.dictList = dictRes.data
    }
    this.getData()
  },
  methods: {
    // 搜索
    searchClick() {
      this.params.pageNum = 1
      this.getData()
    },
    //
    async getData() {
      const res = await this.$API.moShi.getMoShiList(this.params)
      if (res.status != 200) return this.$message.error(res.msg)
      const { records, total } = res.data
      this.records = records
      this.total = parseInt(total)
    },

    // 添加
    addClick() {
      this.rowData = ''
      this.moShiDia = true
    },
    // 编辑
    updateClick(row) {
      this.moShiDia = true
      this.rowData = row
    },
    // 弹框上 确定 click
    async diaQueDingClick() {
      this.moShiDia = false
      this.getData()
    },

    //  删除
    async deleteItem(row) {
      const params = {
        id: row.id,
        tenantName: row.tenantName,
      }
      this.$confirm('此操作将永久删除该模式, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async (res) => {
          if (res == 'confirm') {
            const res = await this.$API.moShi.delete(params)
            if (res.status != 200) return this.$message.error(res.msg)
            this.$message.success(res.data)
            this.params.pageNum = 1
            this.getData()
          } else {
          }
        })
        .catch((err) => err)
    },
    getDictName(val) {
      if (val) {
        if (this && this.dictList && this.dictList.length) {
          let tmp = this.dictList.find((t) => t.code == val)
          console.log(val, tmp, this.dictList)
          if (tmp) {
            return tmp.name
          }
        }
      }

      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.topDiv {
  display: flex;
  .el-input {
    width: 200px;
    margin-right: 20px;
  }
}

.pagination {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
.el-pagination {
  width: 1000px;
  margin: 20px auto;
}
.el-tag {
  margin: 7px;
}
</style>
