<template>
  <div>
    <el-form :model="myForm" ref="editMoShiRef" :rules="rules" label-width="120px">
      <el-form-item label="模式名称" prop="name">
        <el-input v-model="myForm.name" placeholder="请输入模式名称"></el-input>
      </el-form-item>
      <el-form-item label="模式类型" prop="type">
        <el-select v-model="myForm.type" placeholder="请选择类型">
          <el-option :value="item.code" :label="item.name" v-for="(item, i) in dictList" :key="i"></el-option>
          <!-- <el-option :value="5" label="pk"></el-option> -->
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="题目数量" prop="hiqNum">
        <el-input v-model="myForm.hiqNum" placeholder="题目数量"></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="每日回答次数" prop="ansDayTime">
        <el-input v-model="myForm.ansDayTime" placeholder="每日回答次数"></el-input>
      </el-form-item>
      <el-form-item label="每日回答题数" prop="ansDayNum">
        <el-input v-model="myForm.ansDayNum" placeholder="每日回答题数"></el-input>
      </el-form-item> -->
      <el-form-item label="描述" prop="des">
        <el-input v-model="myForm.des" placeholder="描述"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="quXiaoClick">取 消</el-button>
      <el-button type="primary" @click="diaQueDingClick">确 定</el-button>
    </span>
  </div>
</template>
<script>
/* eslint-disable */
import uploadImg from '@/components/common/uploadImg/Index.vue'
import { makeForm } from '@/utils/tools.js'
export default {
  props: ['rowData', 'dictList'],
  components: { uploadImg },
  data() {
    return {
      myForm: {
        name: '', // 模式名称
        // hiqNum: '', // 题目数量
        ansDayTime: '', // 每日回答次数
        ansDayNum: '', // 每日回答题数
        des: '', //描述
        id: '',
        type: '',
      },
      rules: {
        name: [{ required: true, message: '请输入模式名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        // hiqNum: [{ required: true, message: '请输入题目数量', trigger: 'blur' }],
        // ansDayTime: [{ required: true, message: '请输入次数', trigger: 'blur' }],
        // ansDayNum: [{ required: true, message: '请输入题数', trigger: 'blur' }],
      },
    }
  },
  created() {},
  mounted() {
    if (this.rowData) {
      makeForm(this.myForm, this.rowData)
    }
  },
  methods: {
    // 确定
    async diaQueDingClick() {
      this.$refs.editMoShiRef.validate(async (valid) => {
        if (valid) {
          // 编辑
          if (this.rowData) {
            const res = await this.$API.moShi.update(this.myForm)
            if (res.status !== 200) return this.$message.error(res.msg)
            this.$emit('diaQueDingClick')
          } else {
            this.myForm.ansDayTime = 1
            this.myForm.ansDayNum = 1
            const res = await this.$API.moShi.add(this.myForm)
            if (res.status != 200) return this.$message.error(res.msg)
            this.$emit('diaQueDingClick')
          }
        }
      })
    },
    // 取消
    quXiaoClick() {
      this.$emit('quXiaoClick')
    },
  },
  destroyed() {},
}
</script>
<style lang="scss" scoped></style>
